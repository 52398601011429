<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <template #header>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="mt-2 font-italic">
                            Última actualización el <strong>{{ accountRp.updatedDate ? accountRp.updatedDate : accountRp.createdDate }}</strong> por <strong>{{ accountRp.updatedBy ? accountRp.updatedBy : accountRp.createdBy }}</strong>
                        </div>
                    </div>
                </div>
            </template>
            <b-row class="mt-2">
                <b-col class="col-md-6 col-lg-6">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 25%">Registrado</b-th>
                                <b-td>{{ accountRp.createdDate ? accountRp.createdDate : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Tipo</b-th>
                                <b-td>{{ accountRp.type === 'RECEIVABLE' ? 'Por cobrar' : 'Por pagar' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Concepto</b-th>
                                <b-td>
                                    <span v-if="accountRp.objectType === 'BusinessOrder'"><router-link :to="{ name: 'BusinessOrderShow', params: { id: accountRp.objectId }}"  class="text-info" target="_blank">{{ accountRp.orderFolio }}</router-link></span>
                                    <span v-else-if="accountRp.objectType === 'ServiceOrder'"><router-link :to="{ name: 'ServiceOrderShow', params: { id: accountRp.objectId }}"  class="text-info" target="_blank">{{ accountRp.orderFolio }}</router-link></span>
                                    <span v-else-if="accountRp.objectType === 'Expense'"><router-link :to="{ name: 'ExpenseShow', params: { id: accountRp.objectId }}"  class="text-info" target="_blank">Gasto general {{ accountRp.objectId }}</router-link></span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Importe</b-th>
                                <b-td>$ {{ formatCurrency(accountRp.amount) }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Pagado</b-th>
                                <b-td>$ {{ formatCurrency(accountRp.paidReceiveAmount) }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Saldo</b-th>
                                <b-td>$ {{ formatCurrency(accountRp.balance) }} </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col class="col-md-6 col-lg-6 text-right">
                    <h1 class="font-weight-bolder">Cuenta {{ accountRp.type === 'RECEIVABLE' ? 'por cobrar' : 'por pagar' }}</h1>
                    <h1 class="text-navy font-weight-bolder">{{ accountRp.folio }}</h1>
                    <h3 class="mb-3">
                        <b-badge variant="warning" class="button-sticky-right" v-if="accountRp.status === 'BALANCE'">Por saldar</b-badge>
                        <b-badge variant="primary" class="button-sticky-right" v-if="accountRp.status === 'PAID'">Pagada</b-badge>
                        <b-badge variant="danger" class="button-sticky-right" v-if="accountRp.status === 'CANCELLED'">Cancelada</b-badge>
                    </h3>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="4">{{ accountRp.type === 'RECEIVABLE' ? 'Cliente' : 'Proveedor' }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 50%" colspan="2">Datos</b-th>
                                <b-th style="width: 50%" colspan="2">Contacto</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 10%">Nombre</b-th>
                                <b-td>{{ accountRp.businessEntity.legalName ? accountRp.businessEntity.legalName : 'Sin registro'  }}</b-td>
                                <b-th style="width: 10%">Teléfono 1</b-th>
                                <b-td>{{ accountRp.businessEntity.phone1 ? accountRp.businessEntity.phone1 : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>RFC</b-th>
                                <b-td>{{ accountRp.businessEntity.rfc ? accountRp.businessEntity.rfc : 'Sin registro' }}</b-td>
                                <b-th>Email 1</b-th>
                                <b-td>{{ accountRp.businessEntity.email1 ? accountRp.businessEntity.email1 : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Tipo</b-th>
                                <b-td>{{ accountRp.businessEntity.type === 'CUSTOMER' ? 'Cliente' : 'Proveedor' }}</b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="8">
                                    <span v-if="accountRp.objectType === 'BusinessOrder'">Reservación <router-link :to="{ name: 'BusinessOrderShow', params: { id: accountRp.objectId }}"  class="text-info" target="_blank">{{ accountRp.orderFolio }}</router-link></span>
                                    <span v-else-if="accountRp.objectType === 'ServiceOrder'">Orden de Servicio <router-link :to="{ name: 'ServiceOrderShow', params: { id: accountRp.objectId }}"  class="text-info" target="_blank">{{ accountRp.orderFolio }}</router-link></span>
                                    <span v-else-if="accountRp.objectType === 'Expense'"><router-link :to="{ name: 'ExpenseShow', params: { id: accountRp.objectId }}"  class="text-info" target="_blank">Gasto general {{ accountRp.objectId }}</router-link></span>
                                </b-th>
                            </b-tr>
                            <b-tr class="table-header-color" v-if="businessOrder">
                                <b-th>Sucursal</b-th>
                                <b-th>Pasajeros</b-th>
                                <b-th>Cliente</b-th>
                                <b-th>Elaborado por</b-th>
                                <b-th>Registrado</b-th>
                                <b-th class="text-center">Estatus</b-th>
                            </b-tr>
                            <b-tr class="table-header-color" v-else-if="serviceOrder">
                                <b-th>Fecha</b-th>
                                <b-th>Horario</b-th>
                                <b-th>Operador</b-th>
                                <b-th>Vehículo</b-th>
                                <b-th>Servicio</b-th>
                                <b-th>Elaborado por</b-th>
                                <b-th>Registrado</b-th>
                                <b-th class="text-center">Estatus</b-th>
                            </b-tr>
                            <b-tr class="table-header-color" v-else-if="expense">
                                <b-th>Proveedor</b-th>
                                <b-th>Tipo de pago</b-th>
                                <b-th>Elaborado por</b-th>
                                <b-th>Registrado</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-if="businessOrder">
                                <b-td>{{ businessOrder.branch }}</b-td>
                                <b-td>Adultos: {{ businessOrder.adultsQty }} / Niños: {{ businessOrder.childrenQty }}</b-td>
                                <b-td>{{ businessOrder.businessEntity }}</b-td>
                                <b-td>{{ businessOrder.createdBy }}</b-td>
                                <b-td>{{ businessOrder.createdDate }}</b-td>
                                <b-td class="text-center">
                                    <b-badge variant="warning" v-if="businessOrder.status === 'DRAFT'">Borrador</b-badge>
                                    <b-badge variant="info" v-else-if="businessOrder.status === 'OPEN'">Abierta</b-badge>
                                    <b-badge variant="primary" v-else-if="businessOrder.status === 'CLOSED'">Cerrada</b-badge>
                                    <b-badge variant="danger" v-else-if="businessOrder.status === 'CANCELLED'">Cancelada</b-badge>
                                </b-td>
                            </b-tr>
                            <b-tr v-else-if="serviceOrder">
                                <b-td>{{ serviceOrder.startDate }} - {{ serviceOrder.endDate }}</b-td>
                                <b-td>
                                    <template v-if="serviceOrder.durationType === 'ALL'">Todo el dia</template>
                                    <template v-else>{{ serviceOrder.startTime }} - {{ serviceOrder.endTime }}</template>
                                </b-td>
                                <b-td>{{ serviceOrder.operator }}</b-td>
                                <b-td>{{ serviceOrder.vehicle }}</b-td>
                                <b-td>{{ serviceOrder.service }}</b-td>
                                <b-td>{{ serviceOrder.createdDate }}</b-td>
                                <b-td>{{ serviceOrder.createdBy }}</b-td>
                                <b-td class="text-center">
                                    <b-badge variant="warning"  v-if="serviceOrder.status === 'DRAFT'">Borrador</b-badge>
                                    <b-badge variant="info"  v-else-if="serviceOrder.status === 'OPEN'">Abierta</b-badge>
                                    <b-badge variant="primary"  v-else-if="serviceOrder.status === 'FINISHED'">Terminada</b-badge>
                                    <b-badge variant="danger"  v-else-if="serviceOrder.status === 'CANCELLED'">Cancelada</b-badge>
                                </b-td>
                            </b-tr>
                            <b-tr v-else-if="expense">
                                <b-td>{{ expense.supplier }}</b-td>
                                <b-td>{{ expense.paymentType === 'CASH' ? 'Contado' : 'Crédito' }}</b-td>
                                <b-td>{{ expense.createdBy }}</b-td>
                                <b-td>{{ expense.createdDate }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="text-center" style="width: 4%">#</b-th>
                                <b-th>Concepto</b-th>
                                <b-th style="width: 15%" class="text-right">Cantidad</b-th>
                                <b-th style="width: 15%" class="text-right">{{ accountRp.objectType === 'BusinessOrder' ? 'Precio Unitario' : 'Costo Unitario' }}</b-th>
                                <b-th style="width: 15%" class="text-right">Subtotal</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <template v-if="accountRp.objectType === 'BusinessOrder'">
                                <b-tr v-for="(businessOrderItem, index) in accountRp.businessOrderItems" :key="index">
                                    <b-td class="text-center">{{ index + 1 }}</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col md="12">
                                                <span class="font-weight-bold">{{ businessOrderItem.entityType === 'TOUR' ? 'Tour' : 'Traslado' }}: </span> {{ businessOrderItem.name }}<br>
                                                <template v-if="businessOrderItem.entityType === 'TOUR'"><span class="font-weight-bold">Duración: </span>{{ businessOrderItem.duration }} Día(s)<br></template>
                                                <span class="font-weight-bold">Tipo: </span> {{ businessOrderItem.type === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }}<br>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="12">
                                                <label>Notas</label>
                                                <p>{{ businessOrderItem.notes ? businessOrderItem.notes : 'Sin registro' }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                    <b-td class="text-right">
                                        <b-row>
                                            <b-col md="12">
                                                <template v-if="businessOrderItem.adults && businessOrderItem.type === 'COLLECTIVE'">
                                                    <span class="font-weight-bold">Adultos: </span>{{ businessOrderItem.adults }}<br>
                                                </template>
                                                <template v-if="businessOrderItem.children && businessOrderItem.type === 'COLLECTIVE'">
                                                    <span class="font-weight-bold">Niños: </span>{{ businessOrderItem.children }}
                                                </template>
                                                <template v-if="businessOrderItem.type === 'EXCLUSIVE'">1</template>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                    <b-td class="text-right">
                                        <b-row>
                                            <b-col md="12">
                                                <template v-if="businessOrderItem.adults && businessOrderItem.type === 'COLLECTIVE'">
                                                    <span class="font-weight-bold">Adulto: </span>$ {{ formatCurrency(businessOrderItem.adultsFare) }}<br>
                                                </template>
                                                <template v-if="businessOrderItem.children && businessOrderItem.type === 'COLLECTIVE'">
                                                    <span class="font-weight-bold">Niño: </span>$ {{ formatCurrency(businessOrderItem.childFare) }}
                                                </template>
                                                <template v-if="businessOrderItem.type === 'EXCLUSIVE'">
                                                    <span class="font-weight-bold">Servicio: </span>$ {{ formatCurrency(businessOrderItem.serviceFare) }}
                                                </template>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                    <b-td class="text-right">
                                        <b-row>
                                            <b-col md="12">
                                                <template v-if="businessOrderItem.adults && businessOrderItem.type === 'COLLECTIVE'">
                                                    $ {{ formatCurrency(businessOrderItem.adultsFare * businessOrderItem.adults) }}<br>
                                                </template>
                                                <template v-if="businessOrderItem.children && businessOrderItem.type === 'COLLECTIVE'">
                                                    $ {{ formatCurrency(businessOrderItem.childFare * businessOrderItem.children) }}<br>
                                                </template>
                                                <span class="font-weight-bold">Subtotal: </span>$ {{ formatCurrency(businessOrderItem.total) }}
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                            </template>
                            <template v-else>
                                <b-tr v-for="(expense, index) in accountRp.expenseList" :key="index" :class="{'text-danger': expense.isCancelled}" :style="[expense.isCancelled ? {'text-decoration': 'line-through'} : '']">
                                    <b-td class="text-center">{{ index + 1 }}</b-td>
                                    <b-td>
                                        <b-row>
                                            <b-col md="6">
                                                <span class="font-weight-bold">Nombre: </span> {{ expense.name }}<br>
                                                <span class="font-weight-bold">Registrado por: </span> {{ expense.createdBy }}<br>
                                                <span class="font-weight-bold">Fecha de registro: </span> {{ expense.createdDate }}
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col md="12">
                                                <label>Observaciones</label>
                                                <p>{{ expense.notes ? expense.notes : 'Sin registro' }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                    <b-td class="text-right">
                                        <b-row>
                                            <b-col md="12">
                                                <p>{{ expense.qty }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                    <b-td class="text-right">
                                        <b-row>
                                            <b-col md="12">
                                                <p>$ {{ formatCurrency(expense.unitPrice) }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                    <b-td class="text-right">
                                        <b-row>
                                            <b-col md="12">
                                                <p>$ {{ formatCurrency(expense.unitPrice * expense.qty) }}</p>
                                            </b-col>
                                        </b-row>
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th colspan="4" class="text-right">Total</b-th>
                                <b-th :class="{ 'text-right': true, 'text-danger': accountRp.status === 'CANCELLED' }"
                                      :style="[accountRp.status === 'CANCELLED' ? { 'text-decoration': 'line-through' } : '']">
                                    $ {{ formatCurrency(accountRp.amount) }}
                                </b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="9">Abonos</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="text-center" style="width: 4%">#</b-th>
                                <b-th>Fecha de pago</b-th>
                                <b-th>Folio</b-th>
                                <b-th>Método</b-th>
                                <b-th>Registrado por</b-th>
                                <b-th>Fecha de registro</b-th>
                                <b-th>Transacción</b-th>
                                <b-th>Estatus</b-th>
                                <b-th style="text-align: right">Importe</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(payment, index) in accountRp.payments" :key="payment.guid" :class="{'text-danger': payment.status === 'CANCELLED'}" :style="[payment.status === 'CANCELLED' ? {'text-decoration': 'line-through'} : '']">
                                <b-td class="text-center">{{ index + 1 }}</b-td>
                                <b-td>{{ formatDate(payment.paymentDate) }}</b-td>
                                <b-td><router-link :to="{ name: 'PaymentShow', params: { id: payment.paymentId }}" target="_blank">{{ payment.folio }}</router-link></b-td>
                                <b-td>
                                    <span v-if="payment.method === 'CREDIT_CARD'">Tarjeta de crédito</span>
                                    <span v-if="payment.method === 'DEBIT_CARD'">Tarjeta de débito</span>
                                    <span v-if="payment.method === 'CASH'">Efectivo</span>
                                    <span v-if="payment.method === 'CHECK'">Cheque</span>
                                    <span v-if="payment.method === 'TRANSFER'">Transferencia</span>
<!--                                    <span v-if="payment.method === 'OTHER'">Otros</span>-->
                                </b-td>
                                <b-td>{{ payment.createdBy }}</b-td>
                                <b-td>{{ formatDateTime(payment.createdDate) }}</b-td>
                                <b-td>
                                    {{ payment.transactionFolio }}
                                    <b-button size="sm" variant="outline-primary" v-b-tooltip.hover.top title="Imprimir"
                                              @click="transactionPage(payment)" v-if="payment.accountsInTransaction > 1">
                                        <font-awesome-icon icon="print"></font-awesome-icon>
                                    </b-button>
                                </b-td>
                                <b-td>
                                    <span v-if="payment.status === 'TO_VERIFY'">Por verificar</span>
                                    <span v-if="payment.status === 'VERIFIED'">Verificado</span>
                                    <span v-if="payment.status === 'CANCELLED'">Cancelado</span>
                                </b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(payment.amount) }}</b-td>
                            </b-tr>
                            <b-tr v-if="accountRp.payments.length === 0">
                                <b-td colspan="9" class="text-center font-weight-bold">Sin registros</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th class="text-right" colspan="8">Total</b-th>
                                <b-th class="text-right">$ {{ formatCurrency(accountRp.paidReceiveAmount) }}</b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'AccountRpIndex' }"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button class="ml-2" variant="primary" @click="accountRpPage"><font-awesome-icon icon="print"></font-awesome-icon> Imprimir</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { accountRpUrl, accountRpPageUrl, transactionPageUrl } from '@routes';
import * as constants from '@constants';
import { BusinessOrderItem } from '../../models/businessOrderItem';
import { BusinessEntity } from '../../models/businessEntity';
import { formatCurrency, formatDate, formatDateTime } from '../../js/utilities';
import { Payment } from '../../models/payment';
import { Expense } from '../../models/expense';

export default {
    data() {
        return {
            accountRp: {
                id: 0,
                folio: '',
                orderFolio: '',
                createdDate: '',
                createdBy: '',
                updatedDate: '',
                updatedBy: '',
                objectId: 0,
                objectType: '',
                type: '',
                businessEntity: {},
                amount: 0,
                paidReceiveAmount: 0,
                balance: 0,
                state: '',
                payments: [],
                businessOrderItems: [],
                expenseList: []
            },
            businessOrder: null,
            serviceOrder: null,
            expense: null,
            breadcrumb: {
                title: 'Cuentas',
                path: [
                    {
                        name: 'AccountRpIndex',
                        text: 'Cuentas'
                    }
                ]
            },
            swalTitle: 'Cuentas',
            isLoading: false
        };
    },
    created() {
        this.accountRp.businessEntity = BusinessEntity.create();

        this.loadData();

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        formatDate,
        formatDateTime,
        formatCurrency,
        loadData() {
            this.isLoading = true;

            this.axios.get(accountRpUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.accountRp = response.data.accountRp;
                    this.businessOrder = response.data.businessOrder;
                    this.serviceOrder = response.data.serviceOrder;
                    this.expense = response.data.expense;

                    response.data.payments.forEach(paymentData => {
                        let payment = Payment.create();

                        payment.id = paymentData.id;
                        payment.paymentId = paymentData.paymentId;
                        payment.type = paymentData.type;
                        payment.folio = paymentData.folio;
                        payment.amount = paymentData.amount;
                        payment.method = paymentData.method;
                        payment.createdBy = paymentData.createdBy;
                        payment.createdDate = paymentData.createdDate;
                        payment.paymentDate = paymentData.paymentDate;
                        payment.isApproved = paymentData.isApproved;
                        payment.status = paymentData.status;
                        payment.transactionId = paymentData.transactionId;
                        payment.transactionFolio = paymentData.transactionFolio;
                        payment.accountsInTransaction = paymentData.accountsInTransaction;

                        this.accountRp.payments.push(payment);
                    });

                    response.data.businessOrderItems.forEach(businessOrderItemData => {
                        let businessOrderItem = BusinessOrderItem.create();

                        businessOrderItem.id = businessOrderItemData.id;
                        businessOrderItem.name = businessOrderItemData.name;
                        businessOrderItem.description = businessOrderItemData.description;
                        businessOrderItem.type = businessOrderItemData.type;
                        businessOrderItem.adults = businessOrderItemData.adults;
                        businessOrderItem.adultsFare = businessOrderItemData.adultsFare;
                        businessOrderItem.children = businessOrderItemData.children;
                        businessOrderItem.childFare = businessOrderItemData.childFare;
                        businessOrderItem.serviceFare = businessOrderItemData.serviceFare;
                        businessOrderItem.total = businessOrderItemData.total;
                        businessOrderItem.startDate = businessOrderItemData.startDate;
                        businessOrderItem.endDate = businessOrderItemData.endDate;
                        businessOrderItem.hotel1 = businessOrderItemData.hotel1;
                        businessOrderItem.hotel2 = businessOrderItemData.hotel2;
                        businessOrderItem.flightNumber = businessOrderItemData.flightNumber;
                        businessOrderItem.flightSchedule = businessOrderItemData.flightSchedule;
                        businessOrderItem.notes = businessOrderItemData.notes;
                        businessOrderItem.cancelDescription = businessOrderItemData.cancelDescription;
                        businessOrderItem.status = businessOrderItemData.status;
                        businessOrderItem.passengers = [];
                        businessOrderItem.allPassengers = false;
                        businessOrderItem.service = {
                            id: businessOrderItemData.serviceId,
                            name: businessOrderItemData.name
                        };
                        businessOrderItem.duration = businessOrderItemData.duration;
                        businessOrderItem.entityType = businessOrderItemData.entityType;

                        this.accountRp.businessOrderItems.push(businessOrderItem);
                    });

                    response.data.expenseList.forEach(expenseListItemData => {
                        let expenseListItem = Expense.create();

                        expenseListItem.id = expenseListItemData.id;
                        expenseListItem.objectId = expenseListItemData.objectId;
                        expenseListItem.supplierId = expenseListItemData.supplierId;
                        expenseListItem.name = expenseListItemData.name;
                        expenseListItem.category = expenseListItemData.category;
                        expenseListItem.paymentType = expenseListItemData.paymentType;
                        expenseListItem.unitPrice = expenseListItemData.unitPrice;
                        expenseListItem.qty = expenseListItemData.qty;
                        expenseListItem.total = expenseListItemData.total;
                        expenseListItem.notes = expenseListItemData.notes;
                        expenseListItem.createdBy = expenseListItemData.createdBy;
                        expenseListItem.createdDate = expenseListItemData.createdDate;
                        expenseListItem.status = expenseListItemData.status;
                        expenseListItem.isCancelled = expenseListItemData.isCancelled;
                        expenseListItem.isExtra = expenseListItemData.isExtra;

                        this.accountRp.expenseList.push(expenseListItem);
                    });
                }

                this.$nextTick(() => this.isLoading = false);
            });
        },
        transactionPage(paymentItem) {
            this.isLoading = true;
            this.axios.post(transactionPageUrl(), paymentItem).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = paymentItem.transactionFolio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar comprobante de transacción.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        accountRpPage() {
            this.isLoading = true;
            this.axios.post(accountRpPageUrl(), this.accountRp).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = this.accountRp.folio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar la hoja de cuenta.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: transform 0.5s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(-120%);
}

.fade-enter-to,
.fade-leave {
    transform: translateX(0);
}

.custom-control-label {
    color: #ffffff !important;
}
</style>